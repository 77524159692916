<div matDialogContent>
  <mat-form-field class="form-field">
    <mat-label>Ditt gitLab accessToken</mat-label>
    <input [formControl]="accessTokenCtrl" id="token" matInput name="token">
  </mat-form-field>

  <div>
    <button color="primary"
            mat-raised-button
            [matDialogClose]="accessTokenCtrl.value">Uppdatera gitLab accessToken
    </button>
  </div>
</div>
