import {Component, OnInit} from '@angular/core'
import {FormControl} from '@angular/forms'
import {DeployService} from '../../services/deploy.service'

@Component({
  selector: 'spb-access-token',
  templateUrl: './access-token.component.html',
  styleUrls: ['./access-token.component.scss']
})
export class AccessTokenComponent implements OnInit {

  public accessTokenCtrl = new FormControl<string>('', {nonNullable: true})

  constructor(
    private deployService: DeployService
  ) {
  }

  ngOnInit(): void {
    this.accessTokenCtrl.setValue(this.deployService.getGitLabToken())
    this.accessTokenCtrl.valueChanges.subscribe(this.deployService.setGitLabToken)
  }
}
